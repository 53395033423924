import { Link } from "react-router-dom";
import {Buffer} from 'buffer';
import axios from 'axios';
import React, { useEffect, useState} from 'react';
import "../../styling/Topics.css"
import { useAuth0 } from '@auth0/auth0-react';
import LoadingWheel from "../loading";

export default function DisplayAllTopics() {
  const [topicsData, setTopicsData] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_SERVER_URL}/v1/topics/`)
    .then(response => {
      const topics = response.data;
      setTopicsData(topics)
    })
    .catch(error => {
      console.error('Error fetching topic:', error);
    })
    .finally(() => {
      setLoading(false)
    });
  }
  

//if the page is loading
  if(loading) {
        //display loading message
    return (<>
    <LoadingWheel />
    </>)
}
//if the page isnt loading 
else {

//if there are no topics
if (!topicsData || topicsData.length === 0) {
  return (
    <>
      <h1>Sorry, we cannot find what you are looking for!</h1>
      <p>Please try again later</p>
    </>
  );
}


    //return the html of the component
    return (
<>
<Link style={{marginLeft:"20px"}} className="addResourceBtn" to={"/Resources/Create"}>+Resource</Link>
  <div className="topicsList">
    {topicsData.map((item) => {
      // Determine the link based on the item name
      const link = item.name === "All Resources" ? "/resources" : `/topic/${item._id}`;

      return (
        <div className="topicsListItem" key={item.name}>
          <Link className="itemsListLink" to={link}>
          <div className="itemsListImageContainer"
            style={{ borderRadius:"5px" }}>
            <p style={{ height: "120px", backgroundImage: `url(${item.images[0].file})`,borderRadius:"5px", backgroundSize:"cover",backgroundRepeat:"no-repeat", backgroundPosition:"center" }}></p>
          </div>
            
            <div className="topicsListContainer">
              <h2>{item.name}</h2>
              <p>{item.numOfResources} resources available</p>
            </div>
          </Link>
        </div>
      );
    })}
  </div>
</>
    );
  }

}


////<p>{item.description.slice(0, 50)}...</p>-->}